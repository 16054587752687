import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'

Vue.use(Router)

const viewport = {
  content: 'width=device-width, initial-scale=1.0, user-scalable=no'
}

const router = new Router({
  //mode: 'history',
  //base: __dirname,
  routes: [
    {
      path: '/',
      redirect: '/groups'
    },
    {
      path: '/flowable',
      name: 'Index',
      component: () => import('@/views/flowable/Index'),
      children: [
        {
          path: '/flowable/start',
          name: 'Start',
          component: () => import('@/views/flowable/workspace/Index.vue'),
          meta: {
            title: '发起申请'
          }
        },
        {
          path: '/flowable/selfApply',
          name: 'SelfApply',
          component: () =>
            import('@/views/flowable/workspace/task/ApplyTask.vue'),
          meta: {
            title: '我的申请'
          }
        },
        {
          path: '/flowable/selfTodo',
          name: 'SelfTodo',
          component: () =>
            import('@/views/flowable/workspace/task/TodoTask.vue'),
          meta: {
            title: '我的待办'
          }
        },
        {
          path: '/flowable/selfDone',
          name: 'SelfDone',
          component: () =>
            import('@/views/flowable/workspace/task/DoneTask.vue'),
          meta: {
            title: '我的已办'
          }
        },
        {
          path: '/flowable/selfAbout',
          name: 'SelfAbout',
          component: () => import('@/views/flowable/workspace/task/CcTask.vue'),
          meta: {
            title: '抄送我的'
          }
        }
      ]
    },
    {
      path: '/groups',
      name: 'groups',
      component: () => import('@/views/flowable/design/Groups.vue'),
      meta: {
        title: '表单列表',
        viewport: viewport
      }
    },
    {
      path: '/design',
      name: 'design',
      component: () => import('@/views/flowable/design/Design.vue'),
      meta: {
        title: '表单流程设计',
        viewport: viewport
      }
    },
    {
      path: '/maintainApply',
      name: '/#/maintainApply',
      component: () => import('@/views/maintainApply/Index.vue'),
      meta: {
        title: '故障返修申请',
        noNeedAuth: true
      }
    },
    {
      path: '/standByApply',
      name: '/#/standByApply',
      component: () => import('@/views/standByApply/Index.vue'),
      meta: {
        title: '备机申请',
        noNeedAuth: true
      }
    },
    {
      path: '/partsApply',
      name: '/#/partsApply',
      component: () => import('@/views/partsApply/Index.vue'),
      meta: {
        title: '配件申请',
        noNeedAuth: true
      }
    },
    {
      path: '/fittingData',
      name: '/#/fittingData',
      component: () => import('@/views/fittingData/Index.vue'),
      meta: {
        title: '配件数据管理'
      }
    },
    {
      path: '/partsReturnApply',
      name: '/#/partsReturnApply',
      component: () => import('@/views/partsReturnApply/Index.vue'),
      meta: {
        title: '服务配件归还申请',
        noNeedAuth: true
      }
    },
    {
      path: '/sellOrderApply',
      name: '/#/sellOrderApply',
      component: () => import('@/views/sellOrderApply/Index.vue'),
      meta: {
        title: '服务收费单申请',
        noNeedAuth: true
      }
    },
    {
      path: '/convertApply',
      name: '/#/convertApply',
      component: () => import('@/views/convertApply/Index.vue'),
      meta: {
        title: '设备形态转换申请',
        noNeedAuth: true
      }
    },
    {
      path: '/storageOrderApply',
      name: '/#/storageOrderApply',
      component: () => import('@/views/storageOrderApply/Index.vue'),
      meta: {
        title: '其他入库申请',
        noNeedAuth: true
      }
    },
    {
      path: '/invoiceApply',
      name: '/#/invoiceApply',
      component: () => import('@/views/invoiceApply/Index.vue'),
      meta: {
        title: '发票申请',
        noNeedAuth: true
      }
    },
    {
      path: '/pickingOrderApply',
      name: '/#/pickingOrderApply',
      component: () => import('@/views/pickingOrderApply/Index.vue'),
      meta: {
        title: '领料申请(销库)',
        noNeedAuth: true
      }
    },
    {
      path: '/myApply',
      name: '/#/myApply',
      component: () => import('@/views/myApply/Index.vue'),
      meta: {
        title: '我的申请',
        keepAlive: true
      }
    },
    {
      path: '/myAudit',
      name: '/#/myAudit',
      component: () => import('@/views/myAudit/Index.vue'),
      meta: {
        title: '我的审核',
        keepAlive: true
      }
    },
    {
      path: '/myAudit/detail',
      name: '/#/myAudit/detail',
      component: () => import('@/views/myAudit/Detail.vue'),
      meta: {
        title: '详情'
      }
    },
    {
      path: '/myDevice',
      name: '/#/myDevice',
      component: () => import('@/views/myDevice/Index.vue'),
      meta: {
        title: '我的设备'
      }
    },
    {
      path: '/msgNotice',
      name: '/#/msgNotice',
      component: () => import('@/views/msgNotice/Index.vue'),
      meta: {
        title: '消息通知'
      }
    },
    {
      path: '/myCC',
      name: '/#/MyCC',
      component: () => import('@/views/myCC/Index.vue'),
      meta: {
        title: '我的抄送'
      }
    },
    {
      path: '/reportQuery',
      name: '/#/reportQuery',
      component: () => import('@/views/reportQuery/Index.vue'),
      meta: {
        title: '报表数据查询'
      }
    },
    {
      path: '/downCenter',
      name: '/#/downCenter',
      component: () => import('@/views/downCenter/Index.vue'),
      meta: {
        title: '下载中心'
      }
    },
    {
      path: '/projectManagement',
      name: '/#/projectManagement',
      component: () => import('@/views/projectManagement/Index.vue'),
      meta: {
        title: '工单项目管理'
      }
    },
    {
      path: '/templateManagement',
      name: '/#/templateManagement',
      component: () => import('@/views/templateManagement/Index.vue'),
      meta: {
        title: '工单模板管理'
      }
    },
    {
      path: '/batchCreateWorkOrders',
      name: '/#/batchCreateWorkOrders',
      component: () => import('@/views/batchCreateWorkOrders/Index.vue'),
      meta: {
        title: '批量创建工单'
      }
    },
    {
      path: '/partsType',
      name: '/#/partsType',
      component: () => import('@/views/partsType/Index.vue'),
      meta: {
        title: '配件类型'
      }
    },
    {
      path: '/locationQuery',
      name: '/#/locationQuery',
      component: () => import('@/views/locationQuery/Index.vue'),
      meta: {
        title: '货位数据查询'
      }
    },

    {
      path: '/productManage',
      name: '/#/productManage',
      component: () => import('@/views/productManage/Index.vue'),
      meta: {
        title: '产品管理'
      }
    },
    {
      path: '/orderQuery',
      name: '/#/orderQuery',
      component: () => import('@/views/orderQuery/Index.vue'),
      meta: {
        title: '业务查询',
        keepAlive: true
      }
    },
    {
      path: '/bomManage',
      name: '/#/bomManage',
      component: () => import('@/views/bomManage/Index.vue'),
      meta: {
        title: 'Bom管理'
      }
    },
    {
      path: '/bomManage/detail',
      name: '/#/bomManage/detail',
      component: () => import('@/views/bomManage/Detail.vue'),
      meta: {
        title: 'Bom管理明细'
      }
    },
    {
      path: '/bomQuery',
      name: '/#/bomQuery',
      component: () => import('@/views/bomQuery/Index.vue'),
      meta: {
        title: 'BOM数据查询'
      }
    },
    {
      path: '/bomQuery/detail',
      name: '/#/bomQuery/detail',
      component: () => import('@/views/bomQuery/Detail.vue'),
      meta: {
        title: 'BOM数据查询明细'
      }
    },
    {
      path: '/custReportSummary',
      name: '/#/custReportSummary',
      component: () => import('@/views/custReportSummary/Index.vue'),
      meta: {
        title: '客户报障汇总'
      }
    },
    {
      path: '/inventoryManage',
      name: '/#/inventoryManage',
      component: () => import('@/views/inventoryManage/Index.vue'),
      meta: {
        title: '存货档案管理'
      }
    },
    {
      path: '/createBomQuery',
      name: '/#/createBomQuery',
      component: () => import('@/views/createBomQuery/Index.vue'),
      meta: {
        title: '生产子件查询'
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start()
  //需要按钮权限页面才需要请求菜单按钮接口，不配置默认需要请求
  if (!to.meta.noNeedAuth) {
    await store.dispatch('getMenuAction')
    await store.dispatch('getUserInfo')
  }

  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    let head = document.getElementByTagName('head')
    let meta = document.createElemnet('meta')
    meta.name = 'viewport'
    meta.content = 'width=device-width, initial-scale=1.0, user-scalable=no'
    head[0].appendChild(meta)
  }
  next()

  store.commit('setState', {
    keyName: 'currentRoute',
    val: to
  })
  sessionStorage.setItem('router-path', to.path)
})

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

export default router
